<template>
    <Card class="col-right-card min-height page">
        <p slot="title">外修配件管理</p>
        <Form :label-width="120">
            <FormItem class="FromItemnpt" label="配件名称"><Input clearable placeholder="请输配件名称"
                    v-model="search.sparename" /></FormItem>
            <FormItem class="FromItemnpt" label="外修单号"><Input clearable placeholder="请输外修单号" v-model="search.ordersn" />
            </FormItem>
            <FormItem class="FromItemnpt" label="外修状态">
                <Select v-model="search.bsstatus" clearable>
                    <Option v-for="(item, index) in bsstatus" :value="index" :key="index">{{ item }}</Option>
                </Select>
            </FormItem>
            <Button type="primary" @click="handleSearchAction">查询</Button>
        </Form>
        <div class="gray-nona"></div>
        <Table border stripe :columns="canshu" :data="canshudata" height="520"></Table>

        <div class="my-page">
            <Page :total="totalCount" :current="spage" show-elevator show-sizer show-total @on-change="handlePage"
                @on-page-size-change="handlePageSize" />
            <div style="height:30px;"></div>
        </div>
    </Card>
</template>

<script>
import { getorderitemlist } from "@/api/login-free-api.js";
import { getBusinessstatus } from '@/api/dictionary';
import * as Enum from '@/libs/enum';
import * as tools from '@/libs/tools';
export default {
    data() {
        return {
            search: {
                p: 1, // 当前页码
                t: 10, // 每页显示条数
                sparename: '',
                ordersn: '',
                type: Enum.itemstatus.extra,
                bsstatus: '',
                lossNo: ''
            },
            externalDatas: {},
            spage: 1,
            totalCount: 0,
            canshu: [
                { title: 'ID', key: 'id', align: 'center', minWidth: 100, fixed: 'left' },
                { title: '工单号', key: 'ordersn', align: 'center', minWidth: 150 },
                { title: '询价模式', key: 'quotetype_str', align: 'center', minWidth: 150 },
                { title: '配件名称', key: 'sparename', align: 'center', minWidth: 200 },
                { title: '中标价', key: 'bidmoney', align: 'center', minWidth: 120 },
                { title: '审核价', key: 'checkmoney', align: 'center', minWidth: 100 },
                { title: '还件时间(天)', key: 'givetype', align: 'center', minWidth: 120 },
                { title: '外修状态', key: 'bstatus_str', align: 'center', minWidth: 120 },
                { title: '备注', key: 'remark', align: 'center', minWidth: 300 },
                {
                    title: '操作',
                    key: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Button',
                                {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.handleDetailModal(params.row.id);
                                        }
                                    }
                                },
                                '详情'
                            )
                        ]);
                    }
                }
            ],
            canshudata: [

            ],
            bsstatus: [],
            tableMaxHeight: window.innerHeight - 240
        }
    },
    methods: {
        // 查询
        handleSearchAction() {
            this.search.p = 1;
            this.handleSearch();
        },
        // 分页
        handlePage(value) {
            this.search.p = value;
            this.handleSearch();
        },
        handlePageSize(value) {
            this.search.t = value;
            this.handleSearch();
        },
        // 列表
        handleSearch() {
            let params = this.search;
            getorderitemlist(params).then(res => {
                if (res.errCode == 0) {
                    this.totalCount = parseInt(res.data.total);
                    this.canshudata = res.data.list;
                }
            });
        },
        getbsstatus() {
            getBusinessstatus().then(res => {
                this.bsstatus = res.data;
            });
        },
        handleDetailModal(id) {
            let query = {
                ...this.externalDatas,
                lossNo: this.search.lossNo,
                id: id
            };

            this.$router.push({
                path: '/bussiness/accessory/detail',
                query: query,
            });
        },
    },
    mounted() {
        this.search.lossNo = this.$route.query.lossNo;
        this.externalDatas = this.$route.query || {}; // type, // 1显示审核按钮 2不显示审核按钮
        this.getbsstatus();
        this.handleSearch();
    }
}

</script>

<style lang="less"></style>
<style lang="less" scoped>
@import '../style2.less';
@import '../gongye.less';

.page {
    height: 100%;
    overflow: auto;
    padding: 10px;

    :deep(.ivu-table-fixed-body) {
        height: auto !important;
    }
}
</style>